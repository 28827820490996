<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section
    class="footer-area pt-100px pb-30px bg-gray position-relative overflow-hidden"
  >
    <span class="stroke-shape stroke-shape-1"></span>
    <span class="stroke-shape stroke-shape-2"></span>
    <span class="stroke-shape stroke-shape-3"></span>
    <span class="stroke-shape stroke-shape-4"></span>
    <span class="stroke-shape stroke-shape-5"></span>
    <span class="stroke-shape stroke-shape-6"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 responsive-column-half">
          <div class="footer-item">
            <a href="index.html">
              <img
                src="../assets/img/savior-logo.jpg"
                alt="footer logo"
                class="footer__logo"
                height="80"
                width="80"
              />
            </a>
            <ul class="generic-list-item pt-4">
              <li><a href="tel:+201127648299">الهاتف: 201127648299+</a></li>
              <li>
                <a href="mailto:info@saviorstudy.com">
                  البريد الإلكتروني: info@saviorstudy.com</a
                >
              </li>
              <li>المكان: مدينة النصر, القاهرة, مصر</li>
            </ul>
          </div>
          <!-- end footer-item -->
        </div>
        <!-- end col-lg-3 -->
        <div class="col-lg-3 responsive-column-half">
          <div class="footer-item">
            <h3 class="fs-20 font-weight-semi-bold">سيفيور</h3>
            <span class="section-divider section--divider"></span>
            <ul class="generic-list-item">
              <li><a href="#aboutus"> معلومات عنا</a></li>
              <li><a href="#services">خدماتنا</a></li>
              <li><router-link to="/terms">البنود والظروف</router-link></li>
              <li><router-link to="/privacy">سياسة الخصوصية</router-link></li>
              <li><router-link to="/faq">أسئلة وأجوبة</router-link></li>
            </ul>
          </div>
          <!-- end footer-item -->
        </div>
        <!-- end col-lg-3 -->
        <div class="col-lg-3 responsive-column-half">
          <div class="footer-item">
            <h3 class="fs-20 font-weight-semi-bold">تحميل التطبيق</h3>
            <span class="section-divider section--divider"></span>
            <div class="mobile-app">
              <p class="pb-3 lh-24">
                قم بتنزيل تطبيق الهاتف المحمول الخاص بنا وقم بالتعليم أثناء
                التنقل.
              </p>
              <a
                href="https://apps.apple.com/eg/app/savior-study/id6443859261"
                class="d-block mb-2 hover-s"
                target="_blank"
                ><img
                  src="../assets/images/appstore.png"
                  alt="App store"
                  class="img-fluid"
              /></a>
              <a
                href="https://play.google.com/store/apps/details?id=com.saviorstudy.app"
                class="d-block mb-2 hover-s"
                target="_blank"
                ><img
                  src="../assets/images/googleplay.png"
                  alt="Google play store"
                  class="img-fluid"
              /></a>
              <a
                href="https://appgallery.huawei.com/app/C107202809"
                class="d-block hover-s"
                target="_blank"
                ><img
                  src="../assets/img/huawei-Badge-Black.png"
                  alt="Huawei store"
                  class="img-fluid"
                  width="160"
              /></a>
            </div>
          </div>
          <!-- end footer-item -->
        </div>
        <!-- end col-lg-3 -->
        <div class="col-lg-3 responsive-column-half">
          <div class="footer-item">
            <h3 class="fs-20 font-weight-semi-bold">نحن على</h3>
            <span class="section-divider section--divider"></span>
            <ul class="social-icons social-icons-styled">
              <li class="mr-1">
                <a
                  href="https://www.facebook.com/saviorstudyeg?mibextid=ZbWKwL"
                  class="facebook-bg"
                  target="_blank"
                  ><i class="la la-facebook"></i
                ></a>
              </li>
              <li class="mr-1">
                <a
                  href="https://www.instagram.com/savioregypt22/"
                  class="instagram-bg"
                  target="_blank"
                  ><i class="la la-instagram"></i
                ></a>
              </li>
              <li class="mr-1">
                <a
                  href="https://wa.me/201101186867"
                  class="whatsapp-bg bg-success text-white"
                  target="_blank"
                  ><i class="la la-whatsapp"></i
                ></a>
              </li>
            </ul>
          </div>
          <!-- end footer-item -->
        </div>
        <!-- end col-lg-3 -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
</template>
