<template>
  <header class="header-menu-area bg-white">
    <div
      class="header-top pr-150px pl-150px border-bottom border-bottom-gray py-1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="header-widget">
              <ul
                class="generic-list-item d-flex flex-wrap align-items-center fs-14"
              >
                <li
                  class="d-flex align-items-center pr-3 mr-3 border-left border-left-gray"
                >
                  <a
                    href="https://wa.me/201101186867"
                    class="whatsapp-bg whats-header"
                    target="_blank"
                  >
                    <span>+20 110 118 6867</span
                    ><i class="la la-whatsapp ml-1"></i
                  ></a>
                </li>
                <li class="d-flex align-items-center">
                  <a
                    href="info@saviorstudy.com"
                    class="whatsapp-bg"
                    target="_blank"
                  ></a>
                  <a class="head-email"
                    >info@saviorstudy.com<i class="la la-envelope-o ml-1"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- end header-widget -->
          </div>
          <!-- end col-lg-6 -->
          <!-- <div class="col-lg-6">
            <div
              class="header-widget d-flex flex-wrap align-items-center justify-content-end"
            >
              <div class="btn-group">
                <button
                  class="btn btn-secondary btn-sm dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  العربية
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="../main-file/index.html"
                      >English</a
                    >
                  </li>
                  <li><a class="dropdown-item" href="#">العربية</a></li>
                </ul>
              </div>
            </div>
          </div> -->
          <!-- end col-lg-6 -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-top -->
    <div class="header-menu-content pr-150px pl-150px bg-white">
      <div class="container-fluid">
        <div class="main-menu-content">
          <a href="#" class="down-button"><i class="la la-angle-down"></i></a>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo-box">
                <a href="#" class="logo"
                  ><img
                    src="../assets/img/savior-logo.jpg"
                    alt="logo"
                    height="90"
                    width="90"
                /></a>
                <div class="user-btn-action">
                  <div
                    class="search-menu-toggle icon-element icon-element-sm shadow-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Search"
                  >
                    <i class="la la-search"></i>
                  </div>
                  <div
                    class="off-canvas-menu-toggle cat-menu-toggle icon-element icon-element-sm shadow-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Category menu"
                  >
                    <i class="la la-th-large"></i>
                  </div>
                  <div
                    class="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Main menu"
                  >
                    <i class="la la-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col-lg-2 -->
            <div class="col-lg-10">
              <div class="menu-wrapper">
                <nav class="main-menu font-weight-bold">
                  <ul>
                    <li>
                      <a
                        href="#aboutus"
                        aria-controls="aboutus"
                        aria-selected="false"
                        >معلومات عنا</a
                      >
                    </li>
                    <li>
                      <a
                        href="#services"
                        aria-controls="services"
                        aria-selected="false"
                        >خدماتنا</a
                      >
                    </li>
                    <li>
                      <router-link to="/terms">البنود والظروف</router-link>
                    </li>
                    <li>
                      <router-link
                        to="/privacy"
                        aria-controls="policy"
                        aria-selected="false"
                        >سياسة الخصوصية</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/faq">أسئلة وأجوبة</router-link>
                    </li>
                  </ul>
                  <!-- end ul -->
                </nav>
                <!-- end main-menu -->
                <div class="nav-right-button">
                  <a
                    href="#joinus"
                    class="btn theme-btn d-none d-lg-inline-block"
                    ><i class="la la-user-plus mr-1"></i> انضم إلينا
                  </a>
                </div>
                <!-- end nav-right-button -->
              </div>
              <!-- end menu-wrapper -->
            </div>
            <!-- end col-lg-10 -->
          </div>
          <!-- end row align-items-center -->
        </div>
        <!-- end main-menu-content -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-menu-content -->
  </header>
</template>
