<template>
  <Navbar v-if="showNavbar" />
  <router-view />
  <Footer v-if="showFooter" />
</template>

<script>
import $ from "jquery";
import Navbar from "@/layout/nav-bar.vue";
import Footer from "@/layout/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      showNavbar: true,
      showFooter: true,
    };
  },
  mounted() {
    //back to top button control
    const $dom = $("html, body");
    const $window = $(window);
    const scrollTopBtn = $("#scroll-top");
    if ($window.scrollTop() >= 300) {
      console.log($(this).scrollTop(), "scroll");
      scrollTopBtn.show();
    } else {
      scrollTopBtn.hide();
    }
    $window.on("scroll", function () {
      //header fixed animation and control
      if ($(this).scrollTop() > 200) {
        $(".header-menu-content").addClass("fixed-top");
        // add padding top to show content behind header-menu-content
        $("body").css(
          "margin-top",
          $(".header-menu-content").outerHeight() + "px"
        );
      } else {
        $(".header-menu-content").removeClass("fixed-top");
        // remove padding top from body
        $("body").css("margin-top", "0");
      }

      //back to top button control
      if ($(this).scrollTop() >= 300) {
        console.log($(this).scrollTop(), "scroll");
        scrollTopBtn.show();
      } else {
        scrollTopBtn.hide();
      }
    });
    $(document).on("click", "#scroll-top", function () {
      $($dom).animate({ scrollTop: 0 }, 1000);
    });
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@import "assets/css/bootstrap.min.css";
@import "assets/css/bootstrap-rtl.min.css";
@import "assets/css/line-awesome.css";
@import "assets/css/owl.carousel.min.css";
@import "assets/css/owl.theme.default.min.css";
@import "assets/css/bootstrap-select.min.css";
@import "assets/css/fancybox.css";
@import "assets/css/tooltipster.bundle.css";
@import "assets/css/style.css";
@import "assets/css/style-rtl.css";
</style>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
// body {
//   margin: 0px !important;
// }

// @import "assets/vendor/bootstrap/css/bootstrap-ar.min";
// @import "assets/scss/partials/_variables.css";
// @import "assets/scss/partials/_custom.scss";
</style>
